import React from "react"
import { PlayCircleFilled, SoundFilled } from "@ant-design/icons"
import { Button, Row, Col, Card, Table, Divider, Descriptions } from "antd"
import Layout from "../../../components/headerFooterLayout"
import { navigate } from "gatsby"

export default ({
  html,
  publish_date,
  onSongPlay,
  title,
  authors,
  recordings,
  location,
}) => {
  const performances = recordings.length ? (
    <Card title="Performance Recordings">
      <Table
        style={{ cursor: "pointer" }}
        showHeader={false}
        bordered={false}
        pagination={false}
        size="small"
        onRow={(record, index) => ({
          onClick: () => onSongPlay(record.id),
        })}
        dataSource={recordings}
        columns={[
          {
            title: "Recording",
            dataIndex: "recordingID",
            key: "recordingID",
            width: 10,
            render: (recordingID, record) => {
              if (record.isPlaying) {
                return <SoundFilled />
              } else {
                return <PlayCircleFilled />
              }
            },
          },
          {
            title: "Date",
            dataIndex: "dateStr",
            key: "dateStr",
          },
          {
            title: "Venue",
            dataIndex: "venueStr",
            key: "venueStr",
          },
          {
            title: "Location",
            dataIndex: "locationStr",
            key: "locationStr",
          },
          {
            title: "Source",
            dataIndex: "source",
            key: "source",
          },
          {
            title: "Info",
            dataIndex: "performanceSlug",
            key: "performanceSlug",
            fixed: "right",
            render: performanceSlug => {
              return (
                <Button onClick={() => navigate(performanceSlug)}>Info</Button>
              )
            },
          },
        ]}
      />
    </Card>
  ) : (
    <div />
  )
  return (
    <Layout style={{ padding: "24px" }} currentPath={location.pathname}>
      <Descriptions title={title}>
        <Descriptions.Item label="Publish Date" key="Publish Date">
          {publish_date || "Not Listed"}
        </Descriptions.Item>
        <Descriptions.Item label="Author(s)" key="Authors">
          {authors}
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      <Row gutter={30} justify="space-between">
        <Col md={24} lg={18}>
          {performances}
        </Col>
        <Col md={24} lg={6}>
          <Card title="Lyrics">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}
